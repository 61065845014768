@use '../abstract' as *

.newsletter_form
	display: flex
	flex-direction: column
	gap: 2.5rem
	
	*
		@include correctTransformGlitch
	
	.quote
		@include z-typo-heading-inter-tight-semibold-40-49
	
	.form
		display: flex
		flex-direction: column
		gap: 25px
		
		.container
			display: flex
			flex-direction: column
			gap: 10px
			
			.inputs
				display: flex
				flex-direction: column
				gap: 20px
				
				label
					position: relative
					
					input
						width: 100%
						
						padding: 1rem 2rem
						background-color: $color-blanc-casse
						
						border-radius: 10px
						border: none
						font-size: 1em
						
						box-shadow: inset 0 0 0 0 $color-bleu-principal
						
						transition: box-shadow .3s ease
						
						~ .label
							opacity: 0
							transition: opacity .3s ease
							
							position: absolute
							bottom: 100%
							left: 1rem
							// top: 0
							// transform: translateY(-50%)
							
							font-family: "Inter Tight Medium", sans-serif
							font-size: 12px
						
						&:placeholder-shown
							font-family: inherit
							font-size: inherit
							color: rgba($color-bleu-principal, .5)
						
						&:hover
							box-shadow: inset 0 0 0 3px $color-bleu-principal
						
						&:focus, &:not(:placeholder-shown)
							outline: none
							
							~ .label
								opacity: .4
			
			.legend
				font-size: 12px
				opacity: .5
		
		.send_button
			border: none
			align-self: flex-start
			
			display: flex
			flex-direction: row
			gap: 10px
			
			padding: 15px 25px
			
			@include z-typo-paragraph-inter-tight-semibold-20-24
			
			background-color: $color-bleu-principal
			color: white
			border-radius: 10px
			
			cursor: pointer
			
			&:hover
				background-color: #2D3243
			
			&.sent
				background-color: $color-turquoise-audit
			
			&:disabled
				cursor: not-allowed
	
	&.isFooter
		grid-column: 4 / -1
		
		.quote
			grid-column: 1 / span 3
			max-width: 18ch
		
		.form
			grid-column: 4 / -1
	
	@include zi-media
		gap: 25px
		
		.quote
			@include z-typo-paragraph-inter-tight-semibold-20-24
		
		.send_button
			margin-inline: auto
