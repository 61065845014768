@use '../abstract' as *

.link
	position: relative
	
	display: flex
	width: fit-content
	height: fit-content
	
	.line
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		stroke: $color-orange-principal
		
		pointer-events: none
