@use '../abstract' as *

.footer_container
	@include z-padding-inline
	padding-bottom: var(--padding-block-value)
	overflow: hidden
	
	.left_line
		z-index: -1
		position: absolute
		top: 0
		left: calc(-1 * var(--padding-inline-value) - 2rem)
		
		stroke: $color-orange-principal
	
	.right_line
		position: absolute
		z-index: -1
		
		top: 0
		right: calc(-1 * var(--padding-inline-value))
		transform: translateY(50%)
		
		stroke: $color-orange-principal
	
	.footer
		position: relative
		padding: 4rem var(--padding-inline-value) 2rem
		background: white
		border-radius: 20px
		
		display: flex
		flex-direction: column
		gap: 1rem
		
		> *
			@include correctTransformGlitch
		
		.logo
			max-width: 160px
			height: auto
		
		.center
			display: grid
			grid-template-columns: repeat(8, 1fr)
			justify-content: space-between
			gap: 4rem
			
			.left
				grid-column: 1 / 5
				display: flex
				flex-direction: column
				gap: 1rem
				font-size: 18px
				font-family: "Inter Tight Bold", sans-serif
				@include z-typo-paragraph-inter-tight-bold-18-21
				
				> .links
					display: flex
					flex-direction: row
					gap: 2rem
					justify-content: space-between
					
					.column
						flex: 1
						
						display: flex
						flex-direction: column
						gap: 20px
						
						> a, a.bold
							transition: opacity .3s ease
							white-space: nowrap
							
							&.bold
								@include z-typo-paragraph-inter-tight-bold-18-21
							
							&:hover
								opacity: .5
						
						> .links
							@include z-typo-paragraph-inter-tight-medium-18-21
							display: flex
							flex-direction: column
							gap: 10px
							
							.link
								position: relative
								
								display: flex
								flex-direction: column
								gap: 10px
								
								--footer-link-hover-color: #{$color-orange-principal}
								
								.text
									transition: transform .3s ease, color .3s ease
								
								.arrow
									position: absolute
									top: 50%
									left: 0
									transform: translateY(-50%)
									
									opacity: 0
									
									transition: opacity .3s ease
									
									stroke: var(--footer-link-hover-color)
								
								// Hover animation for elements that are not bold
								&:hover:not(.bold)
									.text
										// 24px : 14px of icon and 10px gap
										transform: translateX(1.5rem)
										color: var(--footer-link-hover-color)
									
									.arrow
										opacity: 1
					
					.social_networks
						display: flex
						flex-direction: column
						gap: 20px
						
						.list
							display: flex
							flex-direction: column
							gap: 1rem
							
							font-family: "Inter Tight Light", sans-serif
							
							.icon
								position: relative
								
								display: flex
								width: 22px
								height: auto
			
			.right
				grid-column: 5 / -1
				display: flex
				flex-direction: column
				gap: 30px
		
		.bottom
			text-align: center
			
			font-size: 11px
			line-height: 14px
			opacity: .45
			margin-top: 2rem
		
		.who_made_it
			text-align: center
			
			font-size: 13px
			line-height: 16px
			opacity: .45
			margin-top: .5rem
			
			display: flex
			flex-direction: row
			justify-content: center
			gap: 12px
			
			a
				text-decoration: underline
		
		.awwwards
			position: absolute
			bottom: 1rem
			right: 0
			
			background-color: black
			
			border-radius: 4px 0 0 4px
			
			&:before
				content: ''
				position: absolute
				top: -.5rem
				bottom: 0
				left: 100%
				width: .3rem
				
				background-color: black
				
				border-radius: 0 999px 999px 0
			
			svg
				display: flex
	
	@include zi-media('>', tablet)
		margin-top: 2rem
	
	@include zi-media
		.footer
			padding: 2rem
			
			.left_line
				left: unset
				right: 0
				top: -10%
			
			.right_line
				top: 60%
				left: 55%
				transform: unset
			
			.logo
				align-self: center
				
				path:last-child
					display: none
			
			.center
				display: flex
				justify-content: center
				width: 100%
				
				.left
					.links
						display: grid
						align-items: flex-start
						width: 100%
						
						grid: 'jobs actualities' 'contact actualities' 'socials socials'
						gap: 30px 32px
						
						font-size: 18px
						
						.column
							&:nth-child(1)
								grid-area: jobs
								align-self: flex-start
							
							&:nth-child(2)
								grid-area: actualities
							
							&:nth-child(3)
								grid-area: contact
								align-self: flex-end
						
						.social_networks
							grid-area: socials
							display: flex
							align-items: center
							gap: 1rem
							
							&:before
								content: ''
								width: 100%
								height: 2px
								background-color: $color-bleu-principal
							
							.list
								gap: 1rem
								
								svg
									width: 20px
				
				.right
					display: none
	
	@include zi-media('<', tablet)
		.footer
			.who_made_it
				display: flex
				flex-direction: column
				gap: .5rem
				
				.divider
					display: none
	@include zi-media('<=', mobile)
		.footer
			.awwwards
				transform-origin: bottom right
				scale: .5
				bottom: .25rem
	@media(max-width: 1400px)
		.footer
			.center
				display: flex
				flex-direction: column
	
	@media(max-width: 375px)
		.footer
			.center
				.left
					.links
						display: flex
						flex-direction: column
						align-items: center
						
						.column
							width: 100%
							align-items: center
